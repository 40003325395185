'use client'

import { useEffect, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { z } from 'zod'

import { browserEnvs } from '@/shared/envs'
import type { CustomerType } from '@/shared/graphql/schema/commonBackend/graphql'
import { usePathname } from '@/shared/navigation'
import { useGlobalStore } from '@/shared/store/provider'
import { logError } from '@/shared/utils/error'
import { getLocalStorageValue, setLocalStorageValue } from '@/shared/utils/localStorage'
import { stepEvent } from '@/shared/utils/sendGTMEvent'

import { getUserStatusForGA } from './getUserStatusForGA'

type UserStatusProps = {
  customerType: CustomerType | undefined
  sha256CustomerId: string
  loggedIn: boolean
  isCustomer: boolean
}

export const UserStatus = () => {
  const [status, setStatus] = useState<UserStatusProps | null>()
  const initial = useRef(false)
  useEffect(() => {
    if (!initial.current) {
      initial.current = true
      getUserStatusForGA()
        .then((data) => {
          setStatus(data)
        })
        .catch((error) => logError(error))
    }
  }, [])
  return initial.current && <UpdateUserStatus status={status} />
}

export const UpdateUserStatus = ({ status }: { status: UserStatusProps | null | undefined }) => {
  const pathname = usePathname()
  const [eventSentPage, setEventSentPage] = useState<string>(pathname)
  const customer = useGlobalStore((state) => state.customer)
  //TODO: Fix it, what is customer type from the status and why we need it how does it works?
  const {
    private: { contractTemplate },
  } = useGlobalStore((store) => store.selectedContract)
  const { setCustomerType } = useGlobalStore((store) => store.gtm)
  const customerType = getLocalStorageValue('customerType', z.string(), '')
  const templateCustomerType = contractTemplate?.customerType
  const initialized = useRef(false)
  const sendEvent = () =>
    stepEvent('user_status_update', {
      is_customer: customer.isExistingCustomer || status?.isCustomer ? 'true' : 'false',
      logged_in: status?.loggedIn ? 'true' : 'false',
      customer_type: customerType,
      site_brand: `fortum ${browserEnvs.NEXT_PUBLIC_COUNTRY}`,
      customer_id: status?.sha256CustomerId,
    })

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      sendEvent()
    }
  }, [])

  useEffect(() => {
    if (eventSentPage !== pathname) {
      sendEvent()
      setEventSentPage(pathname)
    }
  }, [pathname])

  const handleCustomerTypeChange = (customerType: CustomerType) => {
    const type = match(customerType)
      .with('PRIVATE', () => 'B2C')
      .with('ENTERPRISE', () => 'B2B')
      .otherwise(() => '')

    setLocalStorageValue('customerType', type)
    setCustomerType(type)
  }

  useEffect(() => {
    if (templateCustomerType) {
      handleCustomerTypeChange(templateCustomerType)
    } else if (status?.customerType) {
      handleCustomerTypeChange(status.customerType)
    }
    setLocalStorageValue('customerId', status?.sha256CustomerId)
  }, [templateCustomerType, status])

  return null
}
