import { useTranslations } from 'next-intl'
import { useCallback, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'

import {
  Box,
  Button,
  ContentText,
  Grid,
  IconSearch,
  generateColorLuminances,
  spacing,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import type { LinkEntry } from '@/shared/contentful/types'
import { isTypeLink } from '@/shared/contentful/types/contentTypeGuards'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import type { useRouter } from '@/shared/navigation'

import { NavigationContext } from '../../Navigation'
import { LanguageMenu } from '../../parts/LanguageMenu'
import { TopNavigationLink } from '../../parts/Links/TopNavigationLink'

type TopNavigationProps = {
  /**
   * Links to be displayed in the top navigation
   */
  topNavigation: LinkEntry[]
  /**
   * Localized pathname of the current page
   */
  pathname: string
  /**
   * Router instance
   */
  router: ReturnType<typeof useRouter>
  /**
   * Open search callback
   */
  onOpenSearch: () => void
}

/**
 * Top navigation menu and links for desktop
 */
export const TopNavigation = ({
  topNavigation,
  pathname,
  router,
  onOpenSearch,
}: TopNavigationProps) => {
  const t = useTranslations('mainNavigation')
  const theme = useTheme()
  const navigationContext = useContext(NavigationContext)
  const isProduction = navigationContext.isProduction

  const handleTopBarItemClick = useCallback(
    (item: LinkEntry) => {
      if (isTypeLink(item)) {
        const url = getLinkEntryUrl(item)
        if (url) {
          router.push(url)
        }
      }
    },
    [router],
  )

  const selectedTopBarItem = useMemo(() => {
    if (!topNavigation) {
      return
    }

    const matchingItem = topNavigation.find((item) => {
      if (item) {
        const itemUrl = getLinkEntryUrl(item).toLowerCase()
        if (itemUrl === '/' && pathname === '/') {
          return true
        }
        return (
          pathname.toLowerCase().startsWith(itemUrl) &&
          (pathname.toLowerCase() === itemUrl || pathname.toLowerCase()[itemUrl.length] === '/')
        )
      }
      return false
    })

    return matchingItem ? matchingItem.name : topNavigation[0]?.name
  }, [topNavigation, pathname])

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      position="relative"
      zIndex={500}
      backgroundColor={theme.colors.backgroundLightOnDark}
    >
      <Grid display="flex" justifyContent="space-between">
        <Box display="flex">
          {topNavigation &&
            topNavigation.map(
              (topBarItem) =>
                topBarItem && (
                  <TopNavigationLink
                    dataTestId={`top-nav-item-${topBarItem.label}`}
                    key={topBarItem.name}
                    label={topBarItem.label ?? ''}
                    href={getLinkEntryUrl(topBarItem)}
                    isActive={selectedTopBarItem === topBarItem.name}
                    onClick={() => handleTopBarItemClick(topBarItem)}
                  />
                ),
            )}
        </Box>
        <Box display="flex">
          <EffectButton
            data-testid="top-search-button"
            status="plain"
            p={0}
            height={spacing.m}
            border="none"
            borderRadius={0}
            onClick={onOpenSearch}
            display={!isProduction ? 'block' : 'none'}
          >
            <Box
              display="flex"
              height={spacing.m}
              gap={spacing.xxxs}
              alignItems="center"
              ph={spacing.xxs}
            >
              <Icon icon={IconSearch} size={24} color={theme.colors.textLightOnDark} />
              <ContentText color={theme.colors.textLightOnDark} size="xs">
                {t('search')}
              </ContentText>
            </Box>
          </EffectButton>

          <LanguageMenu />
        </Box>
      </Grid>
    </Box>
  )
}

const EffectButton = styled(Button)<{ $isActive?: boolean }>`
  ${({ theme, $isActive }) => css`
    ${$isActive &&
    css`
      &:hover,
      &:focus,
      &:active {
        background-color: ${generateColorLuminances('click', theme).bg};
      }
    `}
    &:hover,
    &:focus {
      background-color: ${generateColorLuminances('click', theme).bg};
    }

    &:active {
      background-color: ${generateColorLuminances('normal', theme, { color: '#071210' }).bg};
    }
  `}
`
