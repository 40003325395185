import deepmerge from 'deepmerge'
import type { DeepPartial } from 'react-hook-form'
import { match } from 'ts-pattern'

import type { Tenant } from '@fortum/global-consent-components'

import type { CbusRequestingSystem } from '@/shared/services/clients/cbusClient'

import type { AppEnv, Country } from './envs'
import { serverEnvs } from './envs'
import type { NextCacheConfig } from './nextCacheConfig'
import { nextCacheConfig } from './nextCacheConfig'

export type AppConfig = {
  appEnv: AppEnv
  commonBackend: {
    graphqlUrl: string
  }
  ciam: {
    clientId: string
    providerId: string
    configurationUrl: string
    signOutUrl: string
    resetPassword: string
  }
  contentful: {
    spaceId: string
    environment: string
    sharedSpaceId: string
    sharedEnvironment: string
  }
  gtm: {
    id?: string
    auth: string
    preview: string
  }
  onetrust: {
    domain: string
  }
  /**
   * Globally disable robot indexing of all pages.
   */
  noIndex: boolean
  /**
   * Config for SoS API calls
   */
  cdp: {
    url: string
    requestingSystem: string
  }
  /**
   * Config for CBUS endpoints
   */
  cbus: Record<
    CbusRequestingSystem,
    {
      url: string
      requestingSystem: string
    }
  >
  /**
   * Config for SE legacy app redirects
   */
  seLegacyApp: {
    url: string
    loggedInProfileUrl: string
  }
  creditCardClient: {
    url: string
    apiKey: string
  }
  bankId: {
    url: string
    fetchTokenUrl: string
    clientId: string
  }
  showTestPages: boolean
  consent: {
    tenant: Tenant
    openWeb: {
      templateId: string
    }
    loggedIn: {
      templateId: string
      channel: string
    }
  }
  basicAuth: BasicAuth | false
  datadogRum: {
    clientToken: string
    applicationId: string
    site: string
    service: string
    ddVersion: string
  }
  search: {
    url: string
  }
  nextCache: NextCacheConfig
}

type BasicAuth = {
  username: string
  password: string
  realm: string
}

/**
 * Get configuration by app environment and country.
 * This configuration is only available on the server.
 * Do not include any hardcoded secrets here.
 */
export const getAppConfig = (
  appEnv: AppEnv,
  _country: Country,
  serverSide: boolean = true,
): AppConfig => {
  if (!process.env.STORYBOOK && typeof window !== 'undefined' && serverSide) {
    throw new TypeError('Unsafely accessing app configs client side!')
  }

  const devConfig: AppConfig = {
    appEnv,
    commonBackend: {
      // NOTE: We use staging Apollo in dev because dev Apollo data is very limited.
      // See below for instructions on how to test dev Apollo locally.
      graphqlUrl: 'https://stgcosapi.fortum.com/gl/all/apollo/graphql',
    },
    ciam: {
      clientId: 'globalwebstaging',
      providerId: 'ciamtest',
      configurationUrl:
        'https://sso-staging.fortum.com/am/oauth2/alpha/.well-known/openid-configuration',
      signOutUrl: 'https://sso-staging.fortum.com/am/oauth2/connect/endSession',
      resetPassword:
        'https://sso-staging.fortum.com/am/XUI/?realm=/alpha&authIndexType=service&authIndexValue=ResetPassword',
    },
    contentful: {
      spaceId: 'bs7i8p83z7um',
      sharedSpaceId: '3osoplch9jmc',
      environment: 'test-1',
      sharedEnvironment: 'test-1',
    },
    gtm: {
      id: 'GTM-KC9QG59K',
      auth: 'QwT_fvxUQCq3eGUivjSnLw',
      preview: 'env-4',
    },
    noIndex: true,
    cdp: {
      url: 'https://devcosapi.fortum.com',
      requestingSystem: 'OPEN_WEB',
    },
    cbus: {
      openWeb: {
        /**
         * NOTE: This supposed to be DEV because of that CDP SOS has not staging environment. To avoid confusion we have to use dev as-well.
         * Otherwise in future different consents might be configured in STG and DEV. What might be misleading when testing it E2E.
         */
        url: 'https://testapi.fortum.com',
        requestingSystem: 'OPEN_WEB',
      },
      loggedIn: {
        url: 'https://qaapi.fortum.com',
        requestingSystem: 'LOGGED_IN',
      },
    },
    seLegacyApp: {
      url: 'https://retail-lisa-eu-qa.herokuapp.com',
      loggedInProfileUrl: 'https://retail-lisa-eu-qa.herokuapp.com?targetPage=profil',
    },
    creditCardClient: {
      url: 'https://sandbox1.ediex.no/inbound-http/api',
      apiKey: serverEnvs.CREDIT_CARD_CLIENT_API_KEY,
    },
    bankId: {
      url: 'https://sso2-staging.fortum.com/am/oauth2/realms/root/realms/bravo/authorize',
      fetchTokenUrl:
        'https://sso-staging.fortum.com/am/oauth2/realms/root/realms/bravo/access_token',
      clientId: 'globalwebstaging',
    },
    showTestPages: true,
    consent: {
      tenant: 'Fortum_Markets_SWEMI',
      openWeb: {
        templateId: '65df4e723c36c101293877f5',
      },
      loggedIn: {
        templateId: '6602ba64352d5d7aad2caefb',
        channel: 'LISA',
      },
    },
    basicAuth: {
      username: process.env.BASIC_AUTH_USERNAME || '',
      password: process.env.BASIC_AUTH_PASSWORD || '',
      realm: 'Restricted',
    },
    datadogRum: {
      applicationId: '2d8a99b8-0ea1-498a-b7a8-338d717d9632',
      clientToken: 'puba50c0762a84a3d38d234ec83c9871d36',
      site: 'datadoghq.eu',
      service: 'global-web',
      ddVersion: process.env.DD_VERSION || '',
    },
    search: {
      url: 'https://dev.fortum.com/se/search',
    },
    onetrust: {
      domain: '018ef09a-0ca4-794a-9b92-ba97f04d8fad-test',
    },
    nextCache: nextCacheConfig,
  }

  const localConfig = deepmerge<AppConfig, DeepPartial<AppConfig>>(devConfig, {
    commonBackend: {
      // Uncomment this to use dev Apollo
      // Also update ciam section to use dev ForgeRock
      //graphqlUrl: 'https://devcosapi.fortum.com/gl/all/apollo/graphql',
    },
    ciam: {
      // Uncomment these to use dev ForgeRock
      // clientId: 'globalwebdev',
      // providerId: 'ciamtest',
      // configurationUrl:
      //   'https://sso-dev.fortum.com/am/oauth2/alpha/.well-known/openid-configuration',
      // signOutUrl: 'https://sso-dev.fortum.com/am/oauth2/connect/endSession',
      // Uncomment to test with short session time-to-life
      // clientId: 'globalwebstagingshort',
    },
    contentful: {
      environment: serverEnvs.CONTENTFUL_ENVIRONMENT ?? 'test-1',
      sharedEnvironment: serverEnvs.CONTENTFUL_SHARED_ENVIRONMENT ?? 'test-1',
    },
    basicAuth: false,
    nextCache: {
      ...nextCacheConfig,
      //Overwrite here any next cache tag config
    },
  })

  const stgConfig = deepmerge<AppConfig, DeepPartial<AppConfig>>(devConfig, {
    contentful: {
      environment: 'master',
      sharedEnvironment: 'master',
    },
    showTestPages: false,
    gtm: {
      id: 'GTM-KC9QG59K',
      auth: 'wEYL16Js5XZx3BAxSPXP6A',
      preview: 'env-3',
    },
    datadogRum: {
      applicationId: 'd9575b6d-0e32-420c-a8c2-d3cbf98f12e4',
      clientToken: 'pub5630b7bb93ba4531b0a23e2039ec0a1f',
    },
    search: {
      url: 'https://staging.fortum.com/se/search',
    },
  })

  // TODO: Update values for production
  const prdConfig = deepmerge<AppConfig, DeepPartial<AppConfig>>(stgConfig, {
    bankId: {
      url: 'https://sso2.fortum.com/am/oauth2/realms/root/realms/bravo/authorize',
      fetchTokenUrl: 'https://sso2.fortum.com/am/oauth2/realms/root/realms/bravo/access_token',
      clientId: 'globalwebprod',
    },
    cdp: {
      url: 'https://cosapi.fortum.com',
    },
    ciam: {
      clientId: 'globalwebprod',
      providerId: 'ciamprod',
      configurationUrl: 'https://sso.fortum.com/am/oauth2/alpha/.well-known/openid-configuration',
      signOutUrl: 'https://sso.fortum.com/am/oauth2/connect/endSession',
      resetPassword:
        'https://sso.fortum.com/am/XUI/?realm=/alpha&authIndexType=service&authIndexValue=ResetPassword',
    },
    commonBackend: {
      graphqlUrl: 'https://cosapi.fortum.com/gl/all/apollo/graphql',
    },
    cbus: {
      openWeb: {
        url: 'https://api.fortum.com',
        requestingSystem: 'OPEN_WEB',
      },
      loggedIn: {
        url: 'https://api.fortum.com',
        requestingSystem: 'LOGGED_IN',
      },
    },
    seLegacyApp: {
      url: 'https://www.mittfortum.se',
      loggedInProfileUrl: 'https://www.mittfortum.se?targetPage=profil',
    },
    creditCardClient: {
      url: 'https://edi.edigard.com/inbound-http/api',
    },
    noIndex: true, // Set to false once we want to enable indexing in production
    basicAuth: false, // Uncomment before go-live
    consent: {
      openWeb: {
        templateId: '664eda788506891c277f4ec3',
      },
      loggedIn: {
        templateId: '664edb49c562c307bb09a370',
      },
    },
    datadogRum: {
      applicationId: 'f43f29be-2a52-41cc-ab92-c0f34dd53eb7',
      clientToken: 'pub9d4e5df6b7be8dcf829454216abe3f47',
    },
    search: {
      url: 'https://www.fortum.com/se/search',
    },
  })

  return match(appEnv)
    .returnType<AppConfig>()
    .with('local', () => localConfig)
    .with('dev', () => devConfig)
    .with('stg', () => stgConfig)
    .with('prd', () => prdConfig)
    .otherwise(() => localConfig) // Tests
}
