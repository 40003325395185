'use client'

import { Box, spacing } from '@fortum/elemental-ui'

import type { LinkEntry, LinkGroupEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

import Heading from '../../../Heading/Heading'
import { MenuLink } from '../../../Links/MenuLink'

export type LinkColumnProps = {
  /**
   * Heading Item for the column
   */
  titleLink?: LinkEntry | null
  /**
   * Array with groups of links, with optional subheading
   * There will be gap added in layout between each group
   */
  linkGroups?: LinkGroupEntry[] | null
  /**
   * Localized pathname of the current page
   */
  pathname: string
  /**
   * Index of the column
   */
  indexColumn: number
  /**
   * Is the device desktop
   */
  isDesktop: boolean
}

export const LinkColumn = ({
  titleLink,
  linkGroups,
  pathname,
  indexColumn,
  isDesktop,
}: LinkColumnProps) => {
  const { colors } = useTheme()

  if (!titleLink) {
    return
  }

  const isFirstColumn = indexColumn === 0

  if (isDesktop) {
    return (
      <Box flex="1">
        <Box
          display="flex"
          flexDirection="column"
          gap={spacing.xxs}
          p={`${spacing.m} ${spacing.xxs}`}
        >
          {titleLink && (
            <Heading data-testid={`title-link-${titleLink.label}`} titleLink={titleLink} />
          )}
          <Box>
            {linkGroups?.filter(Boolean).map((group, index) => (
              <Box display="flex" flexDirection="column" key={index}>
                <MenuLink
                  dataTestId={`menu-link-${group.label}`}
                  primaryText={group.label ?? ''}
                  url={getLinkEntryUrl(group)}
                  active={getLinkEntryUrl(group) === pathname}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      pv={spacing.m}
      marginHorizontal={{ default: spacing.xs, xl: 0 }}
      borderTop={titleLink && !isFirstColumn ? `1px solid ${colors.borderPrimary}` : undefined}
    >
      {titleLink && (
        <Heading
          data-testid={`mobile-title-link-${titleLink.label}`}
          titleLink={titleLink}
          mb={spacing.xxs}
        />
      )}
      {linkGroups?.filter(Boolean)?.map((group, index) => (
        <Box display="flex" flexDirection="column" key={index}>
          <MenuLink
            dataTestId={`mobile-menu-link-${group.label}`}
            primaryText={group.label ?? ''}
            url={getLinkEntryUrl(group)}
            active={getLinkEntryUrl(group) === pathname}
          />
        </Box>
      ))}
    </Box>
  )
}
