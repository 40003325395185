'use client'

import { usePathname } from '@/shared/navigation'
import { useMainLayoutContent } from '@/shared/providers/mainLayoutContent'
import { routes } from '@/shared/routes'

import type { NavigationProps } from './Navigation'
import { Navigation } from './Navigation'

export type NavigationConnectorProps = Pick<NavigationProps, 'isProduction' | 'searchUrl'>

export const NavigationConnector = ({ searchUrl, isProduction }: NavigationConnectorProps) => {
  const { mainNavigation, topNavigationLinks } = useMainLayoutContent()

  const pathname = usePathname()

  if (!mainNavigation) {
    return null
  }

  const isBusiness = pathname.includes(routes.ENTERPRISE_HOME)
  // Select the correct main navigation based on the URL
  const selectedMainNav = isBusiness ? mainNavigation.enterprise : mainNavigation.private

  if (!selectedMainNav) {
    return null
  }

  return (
    <Navigation
      searchUrl={searchUrl}
      isProduction={isProduction}
      mainNavigation={selectedMainNav}
      topNavigation={topNavigationLinks}
    />
  )
}
