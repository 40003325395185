import type {
  ServiceAnnouncementLoggedInEntry,
  ServiceAnnouncementOpenWebEntry,
} from '@/shared/contentful/types'
import { getPageRoutePath } from '@/shared/contentful/utils'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'

export type AnyServiceAnnouncement =
  | ServiceAnnouncementLoggedInEntry
  | ServiceAnnouncementOpenWebEntry

export type ServiceAnnouncementWithPageUrl = Pick<
  AnyServiceAnnouncement,
  'type' | 'hideCloseButton' | 'content' | 'name' | 'title'
> & {
  pageUrl: string
  id: string
}

export const getFullPathForOpenWebPages = (
  openWebPages: ServiceAnnouncementOpenWebEntry['pagesCollection'],
) =>
  openWebPages?.items?.filter(isNotNullOrUndefined).map((page) =>
    getPageRoutePath({
      slug: page.slug,
      __typename: page.__typename,
    }),
  ) ?? []
